import _ from 'lodash';

// moment.js
import moment from 'moment/moment';
import 'moment/locale/de';

// jQuery
import $ from 'jquery';
import Echo from 'laravel-echo';

import Pusher from 'pusher-js';

// Livewire
import {Alpine, Livewire} from '../../vendor/livewire/livewire/dist/livewire.esm';
import collapse from '@alpinejs/collapse'
import Tooltip from "@ryangjchandler/alpine-tooltip";
import AlpineLazyLoadAssets from "alpine-lazy-load-assets";
import AsyncAlpine from 'async-alpine';
import '@nextapps-be/livewire-sortablejs';
import {livewire_hot_reload} from 'virtual:livewire-hot-reload';

// Flatpickr
import flatpickr from "flatpickr";
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin';
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/l10n/default';
import 'flatpickr/dist/l10n/de';

// AirDatepicker
import AirDatepicker from 'air-datepicker';
import localeEn from 'air-datepicker/locale/en';
import localeDe from 'air-datepicker/locale/de';
import localeFr from 'air-datepicker/locale/fr';
import localeAr from 'air-datepicker/locale/ar';

// Tempus Dominus
import {DateTime, loadLocale, locale, Namespace, TempusDominus} from '@eonasdan/tempus-dominus';
import {localizationDe, nameDe} from "@eonasdan/tempus-dominus/dist/locales/de";
import {localizationFr, nameFr} from "@eonasdan/tempus-dominus/dist/locales/fr";
import {localizationAr, nameAr} from "@eonasdan/tempus-dominus/dist/locales/ar";

// Powergrid
import './../../vendor/power-components/livewire-powergrid/dist/powergrid';
import "./../../vendor/power-components/livewire-powergrid/dist/powergrid.css";

// SweetAlerts2
import Swal from 'sweetalert2';

// Leaflet
import L from 'leaflet/dist/leaflet';

// Print.js
import printJS from "print-js-updated";

// Lightbox 2
import 'lightbox2/dist/js/lightbox.min';


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
import axios from 'axios';

window._ = _;

moment.locale('de');
window.moment = moment;

window.jQuery = window.$ = $

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
});

AsyncAlpine.init(Alpine);
AsyncAlpine.start();

Alpine.plugin(collapse)
Alpine.plugin(Tooltip);
Alpine.plugin(AlpineLazyLoadAssets);

livewire_hot_reload();

Livewire.start();

window.FlatPickr = flatpickr;
window.rangePlugin = rangePlugin;
window.monthSelect = monthSelect;

window.AirDatepicker = AirDatepicker;
window.AirDatepicker.Locale = {};
window.AirDatepicker.Locale.En = localeEn;
window.AirDatepicker.Locale.De = localeDe;
window.AirDatepicker.Locale.Fr = localeFr;
window.AirDatepicker.Locale.Ar = localeAr;

window.TempusDominus = TempusDominus;
window.TempusDominus.defaultLocale = nameDe;
window.TempusDominus.Namespace = Namespace;
window.TempusDominus.DateTime = DateTime;

//load the locale
loadLocale({localizationDe, nameDe});
loadLocale({localizationFr, nameFr});
loadLocale({localizationAr, nameAr});

locale(nameDe);

window.Swal = Swal;

L.Icon.Default.imagePath = '/assets/leaflet/';

window.printJS = printJS;


window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

